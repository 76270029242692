<template>
    <div class="container">
        <div class="row no-gutter">
            <div class="col p-0">
                <div class="claim-form mt-4">
                    <div class="claim-form__header">
                        <div>
                            <h2 class="claim-form__title">Claim Form</h2>
                            <p class="claim-form__subtitle claim-form__subtitle--light">Start your claim today</p>
                        </div>
                        
                        <div class="claim-form__icon">
                            <img :src="imgurl" alt="" />
                        </div>
                    </div>
                    <div class="claim-form__body">
                        <form-wizard 
                            title="" 
                            subtitle="" 
                            stepSize="xs" 
                            color="#00b050"
                            finishButtonText="Submit"
                            @on-change="handleWizardChange">
                            <tab-content title="Your Details">
                                <!-- page 1 -->
                                <ul class="claim-form__ul" type="none">
                                    <li class="claim-form__section section">
                                        <div class="row no-gutter w-100">
                                            <div class="col-12 col-sm-6">
                                                <FormulateInput 
                                                    type="text"
                                                    class="w-100"
                                                    name="first-name"
                                                    label="Name"
                                                    validation="required"
                                                    help="First"
                                                    v-model="claimerDetails.firstName" />
                                            </div>
                                            <div class="col-12 pl-sm-4 col-sm-6">
                                                <FormulateInput 
                                                    type="text"
                                                    class="w-100 label-transparent"
                                                    label="Last Name"
                                                    name="last-name"
                                                    help="Last"
                                                    v-model="claimerDetails.lastName" />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateForm v-model="claimerDetails">
                                            <FormulateInput
                                                type="group"
                                                name="address"
                                                class="w-100"
                                                label="Address"
                                                validation="required">
                                                <div class="row no-gutter">
                                                    <div class="col-12 mb-3">
                                                        <FormulateInput 
                                                            type="text"
                                                            class="w-100"
                                                            name="address"
                                                            validation="required"
                                                            help="Street Address"/>
                                                    </div>
                                                    <div class="col-12 col-sm-6 mb-3">
                                                        <FormulateInput 
                                                            type="text"
                                                            class="w-100"
                                                            name="suburb"
                                                            validation="required"
                                                            help="Suburb"/>
                                                    </div>
                                                    <div class="col-12 col-sm-6 pl-sm-4 mb-3">
                                                        <FormulateInput 
                                                            type="select"
                                                            class="w-100"
                                                            name="state"
                                                            :options="stateOptions"
                                                            validation="required"
                                                            help="State"/>
                                                    </div>
                                                    <div class="col-6 mb-3">
                                                        <FormulateInput 
                                                            type="text"
                                                            name="postcode"
                                                            validation="required"
                                                            help="Post Code"/>
                                                    </div>
                                                </div>
                                            </FormulateInput>
                                        </FormulateForm>
                                    </li>

                                    <li class="claim-form__section section">
                                        <FormulateInput 
                                            type="email"
                                            label="Email"
                                            name="email"
                                            validation="required|email"
                                            v-model="claimerDetails.email" />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="tel"
                                            label="Phone"
                                            name="phone"
                                            validation="required"
                                            v-model="claimerDetails.phone" />
                                    </li>

                                </ul>
                            </tab-content>

                            <tab-content title="Your Vehicle">
                                <!-- page 2 -->
                                <ul class="claim-form__ul" type="none">
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="text"
                                            label="Rego Number"
                                            name="rego-number"
                                            validation="required"
                                            v-model="vehicleDetails.rego"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="text"
                                            label="Make"
                                            name="make"
                                            validation="required"
                                            v-model="vehicleDetails.make"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="text"
                                            label="Model"
                                            name="model"
                                            validation="required"
                                            v-model="vehicleDetails.model"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="number"
                                            label="Year of Manufacture"
                                            name="dom"
                                            validation="required"
                                            v-model="vehicleDetails.dom"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="select"
                                            label="Are you the registered owner?"
                                            name="registered-owner"
                                            :options="{true: 'Yes', false: 'No'}"
                                            validation="required"
                                            v-model="vehicleDetails.registeredOwner"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="select"
                                            label="Do you have any other vehicles registered in your name?"
                                            name="have-other-vehicles"
                                            :options="{true: 'Yes', false: 'No'}"
                                            validation="required"
                                            v-model="vehicleDetails.haveOtherVehicles"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="vehicleDetails.haveOtherVehicles">
                                        <FormulateInput
                                            type="text"
                                            label="Why can you not use this vehicle during the repair?"
                                            name="why-cannot-use-other"
                                            validation="required"
                                            v-model="vehicleDetails.whyCannotUseOther"
                                        />
                                    </li>
                                </ul>
                            </tab-content>

                            <tab-content title="Accident Details">
                                <!-- page 3 --> 
                                <ul class="claim-form__ul" type="none">
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="date"
                                            name="date"
                                            label="Date & Time of Accident"
                                            placeholder="dd/mm/yyyy"
                                            help="dd/mm/yyyy"
                                            validation="required"
                                            min="2018-12-01"
                                            max="2031-01-01"
                                            v-model="accidentDetails.date"
                                        />
                                    </li>

                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            v-model="accidentDetails.fault"
                                            :options="{otherParty: 'Other party', me: 'Me'}"
                                            type="radio"
                                            label="Who was at fault in the accident" 
                                        />
                                    </li>
                                    
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="textarea"
                                            v-model="accidentDetails.description"
                                            label="Accident Description"
                                            rows="6"
                                            validation="required"
                                            help="Describe how the accident happened"
                                        />
                                    </li>
                                    
                                    <li class="claim-form__section section">
                                        <FormulateForm v-model="accidentDetails">
                                            <FormulateInput
                                                type="group"
                                                name="address"
                                                class="w-100"
                                                validation="required">
                                                    <FormulateInput   
                                                        type="text"
                                                        label="Location of Accident"
                                                        help="Address Line 1"
                                                        class="w-100"
                                                        validation="required"
                                                        name="address1"
                                                    />
                                                    <FormulateInput   
                                                        type="text"
                                                        help="Address Line 2"
                                                        class="w-100 label-transparent"
                                                        label=" "
                                                        validation="required"
                                                        name="address2"
                                                    />
                                                    <div class="row no-gutter">
                                                        <div class="col-12 col-sm-6 mb-3">
                                                            <FormulateInput 
                                                                type="text"
                                                                name="city"
                                                                validation="required"
                                                                help="City"
                                                            />
                                                        </div>
                                                        <div class="col-12 col-sm-6 pl-sm-4 mb-3">
                                                            <FormulateInput 
                                                                type="select"
                                                                class="w-100"
                                                                name="state"
                                                                :options="stateOptions"
                                                                validation="required"
                                                                help="State/Region/Province"
                                                            />
                                                        </div>
                                                        <div class="col-6 mb-3">
                                                            <FormulateInput 
                                                                type="text"
                                                                name="postcode"
                                                                validation="required"
                                                                help="Postal / Zip Code"/>
                                                        </div>
                                                    </div>
                                            </FormulateInput>
                                        </FormulateForm>
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="radio"
                                            label="Were you injured in the Accident"
                                            name="injured"
                                            :options="{true: 'Yes', false: 'No'}"
                                            validation="required"
                                            v-model="accidentDetails.injured"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="accidentDetails.injured">
                                        <FormulateInput   
                                            type="select"
                                            label="Would you like us to contact you regarding compensation for any injuries you incurred"
                                            name="injured"
                                            :options="{true: 'Yes', false: 'No'}"
                                            validation="required"
                                            v-model="accidentDetails.contactForInjuries"
                                        />
                                    </li>
                                </ul>
                            </tab-content>
                            
                            <tab-content title="At Fault Party's Details">
                                <!-- Page 4 -->
                                <ul class="claim-form__ul" type="none">
                                    <li class="claim-form__section section">
                                        <div class="row no-gutter w-100">
                                            <div class="col-12 col-sm-6">
                                                <FormulateInput   
                                                    type="text"
                                                    class="w-100"
                                                    name="driver-first-name"
                                                    validation="required"
                                                    help="First"
                                                    label="At fault driver name"
                                                    v-model="faultPartyDetails.driverFirstName"
                                                />
                                            </div>
                                            <div class="col-12 col-sm-6 pl-sm-4">
                                                <FormulateInput   
                                                    type="text"
                                                    class="w-100 label-transparent"
                                                    name="driver-last-name"
                                                    label="last name"
                                                    validation="required"
                                                    help="Last"
                                                    v-model="faultPartyDetails.driverLastName"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="tel"
                                            label="Phone"
                                            name="phone"
                                            validation="required"
                                            v-model="faultPartyDetails.phone"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="text"
                                            label="Rego"
                                            name="rego"
                                            validation="required"
                                            v-model="faultPartyDetails.rego"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="select"
                                            label="Their Insurer"
                                            :options="{aami: 'AAMI', allianz: 'ALLIANZ', budget: 'BUDGET', nrma: 'NRMA', qbe: 'QBE', suncorp: 'SUNCORP', youi: 'YOUI', other: 'Other'}"
                                            name="their-insurer"
                                            validation="required"
                                            v-model="faultPartyDetails.theirInsurer"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="faultPartyDetails.theirInsurer === 'other'">
                                        <FormulateInput   
                                            type="text"
                                            label="Other Insurer"
                                            name="other-insurer-name"
                                            validation="required"
                                            help="Add the name of the Insurer you use here."
                                            v-model="faultPartyDetails.otherInsurerName"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput   
                                            type="text"
                                            label="Their Insurance claim number"
                                            name="claim-number"
                                            validation="required"
                                            v-model="faultPartyDetails.claimNumber"
                                        />
                                    </li>
                                </ul>
                            </tab-content>

                            <tab-content title="Reasons for Hire">
                                <!-- page 5 -->
                                <ul class="claim-form__ul" type="none">
                                    <li class="claim-form__section section">
                                        <h2>Reasons For Hire</h2>
                                        <p>I require a replacement bike for the following reasons:</p>
                                    </li>
                                    
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I am a delivery rider" 
                                            name="delivery-rider"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="hireReasons.deliveryRider"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="hireReasons.deliveryRider == 'true'">
                                        <FormulateInput
                                            type="text"
                                            label="What delivery company do you work for" 
                                            name="delivery-company"
                                            v-model="hireReasons.deliveryCompany"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I commute to and from work" 
                                            name="commute-to-work"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="hireReasons.commuteToWork"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="hireReasons.commuteToWork == 'true'">
                                        <FormulateInput
                                            type="text"
                                            label="What company do you work for" 
                                            name="work-company"
                                            v-model="hireReasons.workCompany"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="hireReasons.commuteToWork == 'true'">
                                        <FormulateInput
                                            type="text"
                                            label="What is the address" 
                                            name="work-company-address"
                                            v-model="hireReasons.workCompanyAddress"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I commute to and from university / college" 
                                            name="commute-to-college"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="hireReasons.commuteToCollege"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="hireReasons.commuteToCollege == 'true'">
                                        <FormulateInput
                                            type="text"
                                            label="What is the name of the institute" 
                                            name="college-name"
                                            v-model="hireReasons.collegeName"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I agree that public transport is not a suitable alternative" 
                                            name="public-transport-not-suitable"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="hireReasons.publicTransportNotSuitable"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="checkbox"
                                            label="I use my bike as it offers the convenience of:" 
                                            name="public-transport-not-suitable"
                                            :options="{
                                                parking: 'Motorbike parking',
                                                busLanes: 'Use of bus lanes',
                                                laneSplit: 'Ability to lane split',
                                                cheaperTolls: 'Cheaper tolls',
                                                cheaperPetrol: 'Cheaper petrol',
                                            }"
                                        v-model="hireReasons.useMyBikeBecause"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput 
                                            type="checkbox"
                                            label="I require a similar bike to my own for the following reasons:"
                                            name="require-similar-bike-reasons"
                                            :options="{
                                                safety: 'Safety',
                                                powerAndBraking: 'Familiarity of power & braking',
                                                carrying: 'Carrying pillions'
                                            }"
                                            v-model="hireReasons.requireSimilarBikeReasons"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I agree that a car would be an unsuitable replacement for me" 
                                            name="car-is-unsuitable"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="hireReasons.carIsUnsuitable"
                                        />
                                    </li>
                                </ul>
                            </tab-content>
                            <tab-content title="Complete Application">
                                <!-- page 6 -->
                                <ul class="claim-form__ul" type="none">
                                    <li class="claim-form__section section">
                                        <h2>Complete Application</h2>
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput 
                                            type="checkbox"
                                            label="Is your bike in a rideable condition"
                                            name="rideable-condition"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="completeApplication.rideableCondition"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I require a replacement bike as soon as possible"
                                            name="commute-to-college"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="completeApplication.asSoonAsPossible"
                                        />
                                    </li>
                                    <li class="claim-form__section section" v-if="completeApplication.asSoonAsPossible">
                                        <FormulateInput
                                            type="date"
                                            name="replacementBikeDate"
                                            label="Do you have a preferred date you require a replacement bike"
                                            help="dd/mm/yyyy"
                                            validation="required|after:2019-01-01"
                                            min="2018-12-01"
                                            max="2031-01-01"
                                            error-behavior="live"
                                            v-model="completeApplication.replacementBikeDate"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="text"
                                            label="Who is your repairer?" 
                                            help="Add your Repairers Business name"
                                            name="repair-name"
                                            v-model="completeApplication.repairerName"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="checkbox"
                                            label="How did you hear about us" 
                                            name="public-transport-not-suitable"
                                            :options="{
                                                repairer: 'Repairer',
                                                online: 'Online',
                                                tv: 'TV',
                                                towing: 'Towing Company',
                                                other: 'Other'
                                            }"
                                        v-model="completeApplication.hearAboutUs"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="Are you lodging a claim with your own insurance company?"
                                            name="own-insurance-company"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="completeApplication.ownInsuranceCompany"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="text"
                                            label="Provide your claim number" 
                                            name="claim-number"
                                            v-model="completeApplication.claimNumber"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="text"
                                            label="Your Insurance Company name" 
                                            name="insurance-company-name"
                                            v-model="completeApplication.insuranceCompanyName"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <FormulateInput
                                            type="radio"
                                            label="I confirm the information i have provided is true"
                                            validation="required"
                                            name="information-is-true"
                                            :options="{true: 'Yes', false: 'No'}"
                                            v-model="completeApplication.informationIsTrue"
                                        />
                                    </li>
                                    <li class="claim-form__section section">
                                        <div class="formulate-input-wrapper">
                                            <label class="formulate-input-label formulate-input-label--before font-16 font-nunito--bold {">Your Signature <span style="color: red;">*</span></label>
                                            <div class="claim-form__sign-wrapper">
                                                <VueSignaturePad
                                                    id="signature"
                                                    width="100%"
                                                    height="208px"
                                                    ref="signaturePad"
                                                    :options="{ penColor: '#1C1F39'}"
                                                />
                                            </div>
                                            <a href="#" class="font-16 font-nunito-bold mt-2 d-inline-block" @click.prevent="clearSignature">Clear</a>
                                        </div>
                                    </li>
                                </ul>
                            </tab-content>

                        </form-wizard>
                    </div>
                    <div class="claim-form__footer d-none">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'claim-form',
    data() {
        return {
            "claimerDetails": {
                "firstName": "",
                "lastName": "",
                "address": "",
                "suburb": "",
                "state": "",
                "postcode": "",
                "email": "",
                "phone": ""
            },
            "vehicleDetails": {
                "rego": "",
                "make": "",
                "model": "",
                "dom": "",
                "registeredOwner": "",
                "haveOtherVehicles": "",
                "whyCannotUseOther": ""
            },
            "accidentDetails": {
                "date": "",
                "fault": "",
                "description": "",
                "address1": "",
                "address2": "",
                "city": "",
                "state": "",
                "postcode": "",
                "injured": "",
                "contactForInjuries": ""
            },
            "faultPartyDetails": {
                "driverName": "",
                "phone": "",
                "rego": "",
                "theirInsurer": "",
                "otherInsurerName": "",
                "claimNumber": ""
            },
            "hireReasons": {
                "deliveryRider": "",
                "deliveryCompany": "",
                "commuteToWork": "",
                "workCompany": "",
                "workCompanyAddress": "",
                "commuteToCollege": "",
                "collegeName": "",
                "publicTransportNotSuitable": "",
                "useMyBikeBecause": {
                    "parking": "",
                    "busLanes": "",
                    "laneSplit": "",
                    "cheaperTolls": "",
                    "cheaperPetrol": ""
                },
                "requireSimilarBikeReasons":{
                    "safety": "",
                    "powerAndBraking": "",
                    "carrying": ""
                },
                "carIsUnsuitable": ""
            },
            "completeApplication": {
                "rideableCondition": "bool",
                "replacementBikeDate": "date",
                "asSoonAsPossible": "bool",
                "repairerName": "",
                "hearAboutUs": {
                    "repairer": "bool",
                    "online": "bool",
                    "tv": "bool",
                    "radio": "bool",
                    "towing": "bool",
                    "other": "bool"
                },
                "ownInsuranceCompany": "bool",
                "claimNumber": "",
                "insuranceCompanyName": "",
                "informationIsTrue": "bool",
                "signature": "base64 encoded image"
            },
            imgurl: 'https://dev.pay.yourrighttoride.com.au/img/logo.png',
            publishableKey: 'pk_test_TYooMQauvdEDq54NiTphI7jx',
            stateOptions: {
                new_south_wales: 'New South Wales',
                victoria: 'Victoria',
                queensland: 'Queensland',
                western_australia: 'Wwesten Australia',
                south_australia: 'South Australia',
                Tasmania: 'Tasmania',
                northern_teritory: 'Northern Teritory'
            }
        };
    },
    methods: {
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        handleWizardChange(prevIndex, nextIndex) {
            if (nextIndex === 5) {
                // re-calculate signature pad canvas
                this.$nextTick(() => {
                    this.$refs.signaturePad.resizeCanvas();
                });
            }
        }
    }
}
</script>

<style lang="scss">

</style>